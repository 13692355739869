<template>
  <div v-if='isbundle' class="bundle-props-container">
     <table class="table">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Wert</th>
          <th scope="col">Beschreibung</th>
        </tr>
      </thead>
     
      <tbody class="" v-for="item in bundleItemsPros" >
         <td class="table-active" colspan="3">{{item.variationname}}</td>
         <tr v-for="props in item.properties" v-if="!propsIdsAarry.includes(props.propertyId)" >
          <td>{{props.name}}</td>
          <td v-if="typeof props.value !== undefined && props.value !== null && props.value.includes('https')"><a target="_blank" :href="props.value">Datei öffnen</a></td>
          <td v-else>{{props.value}}</td>
          <td>{{props.description}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    propsIds: String,
  },
  inject: {
        itemId: {
            default: null
        }
  },
  data() {
    return {
      bundleItemsPros: null,
      propsIdsAarry : [],
    }
  },
  computed: {
          isbundle() {
            let type = this.$store.getters[`${this.itemId}/currentItemVariation`].variation.bundleType
            return (type == 'bundle' ) ? true : false
              
          
          },
          bundleItemsIds () {
              let bundleItems =  this.$store.getters[`${this.itemId}/currentItemVariation`].bundleComponents;
              let itemsIds = [];
              
              if(bundleItems){
                  bundleItems.forEach((product)=>{
                    itemsIds.push(product.data.variation.id);
                  })
                return itemsIds;

              }else{
                this.isbundle = false
              }
      

          }
  },
  methods: {
    
    postProps() {
      
      axios.post('/rest/getprops/1', {
        items: this.bundleItemsIds
      })
      .then((response) => {

        this.bundleItemsPros = response.data
        
       
      }, (error) => {
        console.log(error);
      });


    }
  },

  mounted () {
     
     if(this.isbundle){
        this.postProps();
        this.propsIdsAarry = this.propsIds.split(',');
        this.propsIdsAarry = this.propsIdsAarry.map(function (x) { 
              return parseInt(x, 10); 
        }); 
     }

  }
}
</script>

<style lang="scss" scoped>
tr.row-border {
    border-top: 4px #008ebd solid;
}

</style>
